.contact__heading {
  max-width: 746px;
  margin-top: 16px;
  margin-bottom: 64px;
}

.contact__text {
  margin-bottom: 64px;
}

.contact--blocks {
  margin-bottom: 30px;
}

.contact {
  .certifications__list {
    border: none;
  }
}

.contact__certifications {
  background: var(--dark-90);
  border-radius: 24px;
  margin-top: 56px;

  .container {
    padding: 0;
  }
}

.offices {
  margin-top: 91px;
  text-align: center;
  padding: 80px 0;
  border-top: 1px solid var(--dark-50);
  border-bottom: 1px solid var(--dark-50);

  a {
    font-size: 19px;
    line-height: 28px;
  }
}

.offices__heading {
  margin-bottom: 24px;
}

.offices__locations {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 24px;

  @include breakpoint(md) {
    flex-direction: row;
    column-gap: 73px;
  }
}

.offices__location {
  text-align: center;
  flex-grow: 1;

  @include breakpoint(md) {
    // flex: 0 0 50%;
  }
}

.offices__title {
  color: var(--secondary-a-80);
  margin-bottom: 16px;
}

.offices__embed {
  margin-top: 48px;
  border-radius: 16px;
  overflow: hidden;

  iframe {
    width: 100%;
    border: none;
  }
}
