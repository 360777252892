html {
  box-sizing: border-box;
  font-size: 16px;
  scroll-behavior: smooth;

  @media screen and (prefers-reduced-motion: reduce) {
    scroll-behavior: auto;
  }
}

body {
  background-color: var(--dark-100);
  font-family: var(--body-font);
  color: var(--white);
  position: relative;
}

body.body--home {
  background: var(--dark-90) url(../images/bg-image-small.jpg) no-repeat top right / 70%;

  @include breakpoint(md) {
    background: var(--dark-90) url(../images/bg-image-large.jpg) no-repeat top right / 60%;
  }
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

.main {
  &:after {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    display: block;
    background: rgba(#4E7998, 0.2);
    top: 0;
    left: 0;
    backdrop-filter: blur(8px);
    z-index: -1;
    opacity: 0;
    transition: opacity 0.3s ease-in, z-index 0.3s ease-in;
    transition-delay: 0.2s;
  }
}

.main--active-menu {
  &:after {
    z-index: 3;
    opacity: 1;
  }
}
