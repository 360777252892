.banner {
  position: relative;
  padding: 218px 0 64px;
  background-color: var(--dark-90);
  border-bottom: 1px solid var(--dark-50);
  z-index: -5;
  overflow: hidden;

  @include breakpoint(md) {
    padding: 308px 0 64px;
  }
}

.banner__title {
  margin-bottom: 12px;

  @include breakpoint(md) {
    margin-bottom: 16px;
  }
}

.banner__tagline {
  margin-bottom: 0;
}

.banner__inner {
  max-width: 856px;
}

.banner__logo {
  padding-left: 30px;
  margin-bottom: 16px;

    @include breakpoint(lg) {
    position: absolute;
    right: -19px;
    bottom: 72px;
    padding-left: 0;
    margin-bottom: 0;

    svg {
      width: 254px;
      height: 180px;
    }
  }
}

.banner--advisory {
  .banner__title {
    font-size: 48px;
    line-height: 58px;
  }
}

.banner__extra-content {
  display: inline-block;
  margin-top: 16px;
}
