.heading-xl,
.heading-l,
.heading-m,
.heading-s,
.heading-xs {
  font-family: var(--heading-font-semi-bold);
  letter-spacing: -1px;
}

.heading-xl {
  font-size: 48px;
  line-height: 58px;

  @include breakpoint(lg) {
    font-size: 64px;
    line-height: 74px;
  }
}

.heading-l {
  font-size: 38px;
  line-height: 56px;

  @include breakpoint(lg) {
    font-size: 48px;
    line-height: 58px;
  }
}

.heading-m {
  font-size: 26px;
  line-height: 34px;

  @include breakpoint(lg) {
    font-size: 38px;
    line-height: 56px;
  }
}

.heading-s {
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0;

  @include breakpoint(lg) {
    font-size: 26px;
    line-height: 34px;
  }
}

.heading-xs {
  font-size: 21px;
  line-height: 34px;
  letter-spacing: 0;
}

.subheading {
  font-family: var(--heading-font-light);
  font-size: 26px;
  line-height: 34px;
}

.body-l,
.body-r,
.body-s {
  font-family: var(--body-font);
  font-weight: 400;
}

.body-l {
  font-size: 19px;
  line-height: 28px;
}

.body-r {
  font-size: 16px;
  line-height: 28px;
}

.body-s {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

p {
  margin-bottom: 24px;
}

.link--external {
  color: var(--white);
  font-size: 19px;
  line-height: 28px;

  svg {
    width: 24px;
    height: 24px;
    margin-left: 8px;
    position: relative;
    top: 3px;
  }
}

ul {
  list-style-image: url(../images/bullet.svg);
  padding-left: 20px;

  li {
    padding-left: 16px;
    margin-bottom: 16px;
  }
}

ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;

  & > li {
    display: table;
    counter-increment: item;
    margin-bottom: 16px;

    &:before {
      content: counters(item, ".") ". ";
      display: table-cell;
      padding-right: 0.6em;
    }
  }

  ol {
    margin-top: 16px;
  }

  li ol > li {
    margin: 0;

    &:before {
      content: counters(item, ".") " ";
    }
  }
}

a {
  color: var(--white);
  transition: color 0.2s ease-in;

  &:hover,
  &:focus {
    color: var(--primary-a-100);
  }

  &:active {
    color: var(--primary-a-80);
  }
}

.link--inverted {
  color: var(--primary-a-100);

  &:hover,
  &:focus {
    color: var(--white);
  }

  &:active {
    color: var(--primary-a-80);
  }
}
