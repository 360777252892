.breadcrumbs {
  padding: 52px 0;
}

.breadcrumbs__inner {
  display: flex;
  align-items: center;
  color: var(--dark-20);
  margin-bottom: 0;
  font-size: 14px;
}

.breadcrumbs__links {
  color: var(--dark-20);
  margin-right: 8px;
  
  &::first-letter {
    text-transform: capitalize;
  }
}

.breadcrumbs__chevron {
  margin-right: 8px;
}
