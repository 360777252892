@font-face {
  font-family: "PoppinsSemiBold";
  src: url("../fonts/Poppins-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "PoppinsLight";
  src: url("../fonts/Poppins-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "InterBold";
  src: url("../fonts/Inter-Bold.ttf");
  font-weight: 700;
}

body {
  --heading-font-semi-bold: "PoppinsSemiBold";
  --heading-font-light: "PoppinsLight";
  --body-font: "Inter";
  --body-font-bold: "InterBold";
}
