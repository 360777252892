.bio {
  object-fit: cover;
  display: flex;
  flex-direction: column;
  padding: 56px 0;
  border-bottom: 1px solid var(--dark-50);

  @include breakpoint(md) {
    flex-direction: row;
  }
}

.bio__image {
  flex: 0 0 197px;
  width: 197px;
  height: 197px;
  border-radius: 48px;
  margin-bottom: 27px;
  object-fit: cover;
  @include breakpoint(md) {
    margin-right: 32px;
    margin-bottom: 0;
  }
}

.bio__name {
  display: block;
}

.bio__role {
  display: block;
  margin-bottom: 24px;
}

.bio__description {
  color: var(--dark-20);
}

.bio__links {
  display: flex;
  margin-top: 32px;
}

.bio__link-divider {
  color: var(--dark-50);
  margin: 0 24px;
}
