.events {
  list-style: none;
  margin: 0;
  padding: 0;
}

.events__list-item {
  display: flex;
  flex-direction: column;
  padding: 0 0 56px 0;
  border-bottom: 1px solid var(--dark-50);
  margin: 0 0 56px 0;

  @include content_typography();

  @include breakpoint(md) {
    flex-direction: row;
  }
}

.events__image {
  flex: 0 0 197px;
  width: 197px;
  height: 197px;
  background-color: var(--white);
  border-radius: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 32px;
  margin-bottom: 32px;

  @include breakpoint(md) {
    margin-bottom: 0;
  }

  img {
    max-height: 197px;
    width: auto;
  }
}

h3.events__title {
  margin-bottom: 24px;
  padding-top: 0;

  a {
    text-decoration: none;
  }
}

.events__date {
  margin-bottom: 8px;
}

.events__date,
.events__venue {
  display: flex;
  align-items: center;
  color: var(--dark-0);

  svg {
    margin-right: 8px;
  }
}

.events__venue-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: var(--dark-0);

  svg {
    margin-right: 8px;
  }
}

.events__description,
.events__talks {
  color: var(--dark-20);
}

.events__download {
  margin-top: 36px;
}
