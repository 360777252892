.hero {
  position: relative;
  padding: 218px 0 168px;
  overflow: hidden;

  @include breakpoint(md) {
    padding: 308px 0 172px;
    text-align: center;
  }
}

.hero__inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 635px;
}

.hero__title {
  margin-bottom: 48px;
}

.hero__tagline {
  margin-bottom: 48px;
  max-width: 638px;
}

.hero__logos {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  padding: 32px 0;
  max-width: 835px;
  width: 100%;
}

.hero__logos-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  row-gap: 40px;
  column-gap: 32px;

  @include breakpoint(md) {
    grid-template-columns: repeat(48, 1fr);
    column-gap: 7px;
  }

  li {
    padding: 0;
    margin: 0;
    grid-column: span 6;
    display: flex;
    align-items: center;

    @include breakpoint(md) {
      grid-column: span 11;

      img {
        margin: auto;
      }

      &:nth-child(1) {
        img {
          margin: 0;
        }
      }

      &:last-child {
        grid-column: span 15;
      }
    }
  }
}

.hero__logo {
  width: 100%;
}

.hero__logo--quantum {
  max-width: 135px;
}

.hero__logo--zx {
  max-width: 130px;
}

.hero__logo--helix {
  max-width: 106px;
}
.hero__logo--cassini {
  max-width: 274px;
}
.hero__logo--cythera {
  max-width: 130px;
}
// ANIMATION STYLES
.hero__title,
.hero__tagline,
.hero__logos {
  opacity: 0;
  position: relative;
  bottom: -10px;
  transition: opacity 0.3s ease-in, bottom 0.4s ease-in;
  text-align: left;
}

.hero__logos {
  bottom: -20px;
}

.hero.animating {
  .hero__title,
  .hero__tagline {
    opacity: 1;
    bottom: 0;
  }

  .hero__logos {
    opacity: 1;
    bottom: 0;
    transition-delay: 0.3s;
  }
}
