body {
  --primary-a-100: #068EF3;
  --primary-a-80: #51B2FB;
  --secondary-a-100: #4E7998;
  --secondary-a-80: #7199B6;
  --dark-100: #141619;
  --dark-90: #1B1E23;
  --dark-50: #565C61;
  --dark-20: #AEB5BC;
  --dark-0: #F1F2F2;
  --white: #FFFFFF;
  --black: #000000;
  --card-bg: rgba(28, 30, 33, 0.40);
}
