.content-page-contact-outer {
  margin: 64px 0px;
  background: url(../images/contact-us-bg.svg) no-repeat center center;
  background-size: cover;
  background-color: var(--dark-90);
  border: 1px solid var(--dark-50);
  border-radius: 24px;

  @include breakpoint(md) {
    background: url(../images/contact-block-bg.svg) no-repeat center center;
    background-size: cover;
    background-color: var(--dark-90);
  }
}

.content-page-contact {
  padding: 56px 40px;

  @include breakpoint(md) {
    padding: 64px 64px 80px;
  }

  @include breakpoint(lg) {
    padding: 64px 110px 80px 110px;
  }
}

.content-page-contact__right {
  text-align: left;
  @include breakpoint(md) {
    text-align: right;
  }
}

.content-page-contact__label {
  display: inline-block;
  margin-bottom: 24px;

  @include breakpoint(md) {
    margin-bottom: 16px;
  }
}

.content-page-contact__heading {
  max-width: 636px;
  padding-bottom: 56px;
  
  @include breakpoint(md) {
    padding-right: 12px;
    padding-bottom: 0px;
  }
}

.content-page-contact__button {
  text-align: center;
}
