.content__column-right--latest-event {
  display: none;

  @include breakpoint(md) {
    display: inline-block;
  }
}

.latest-event {
  margin-top: 32px;
  border-left: 2px solid var(--dark-50);
  padding-left: 24px;

  a {
    text-decoration: none;
  }

  .latest-event__title {
    font-size: 18px;
    line-height: 24px;
    color: var(--dark-0);
  }

  .latest-event__date,
  .latest-event__venue,
  .latest-event__venue-link {
    color: var(--dark-0);
  }

  .latest-event__title,
  .latest-event__date,
  .latest-event__venue {
    margin-bottom: 14px;
  }

  .latest-event__description {
    color: var(--dark-20);
  }

  .latest-event__see-all {
    text-decoration: underline;
    color: var(--primary-a-100);

    &:active {
      color: var(--primary-a-80);
    }
  }
}
