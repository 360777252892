.contact-outer {
  @include breakpoint(md) {
    background: url(../images/contact-us-bg.svg) no-repeat center 36px;
    background-size: 556px 556px;
  }
}

.contact-home {
  padding-top: 80px;
  padding-bottom: 100px;
  text-align: left;

  @include breakpoint(md) {
    text-align: center;
  }
}

.contact-home__label {
  display: inline-block;
  margin-bottom: 16px;
}

.contact-home__heading {
  margin-bottom: 64px;
}

// ANIMATION STYLES
.contact-home__label,
.contact-home__heading,
.contact-home__button {
  opacity: 0;
  position: relative;
  bottom: -10px;
  transition: opacity 0.3s ease-in, bottom 0.4s ease-in;
}

.contact-home__button {
  bottom: -20px;
}

.contact-home.animating {
  .contact-home__label,
  .contact-home__heading {
    opacity: 1;
    bottom: 0;
  }

  .contact-home__button {
    opacity: 1;
    bottom: 0;
    transition-delay: 0.3s;
  }
}
