.advisories__section:first-of-type > .advisories__year {
  margin-top: 0;
}

.advisories__year {
  color: var(--secondary-a-80);
  margin: 56px 0 24px 0;
}

.advisories__list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
  }
}

.advisories__list-item {
  display: block;
  background-color: var(--dark-90);
  border: 1px solid var(--dark-50);
  padding: 32px;
  border-radius: 8px;
  margin: 0 0 24px 0;
  text-decoration: none;
  transition: border-color 0.2s ease-in;

  &:hover,
  &:focus,
  &:active {
    border-color: var(--dark-0);

    .advisories__title,
    .advisories__date,
    .advisories__summary {
      color: var(--white);
    }

    .advisories__view {
      color: var(--primary-a-80);
    }
  }
}

.advisories__title,
.advisories__date {
  display: block;
  margin-bottom: 16px;
  transition: color 0.2s ease-in;
}

.advisories__summary {
  color: var(--dark-20);
  margin-bottom: 40px;
  transition: color 0.2s ease-in;
}

.advisories__view {
  color: var(--primary-a-100);
  text-decoration: underline;
  transition: color 0.2s ease-in;
}
