.header {
  position: absolute;
  width: 100%;
  z-index: 5;
}

.header__inner {
  background-color: var(--dark-100);
  padding: 24px 20px;
  border-radius: 24px;
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  column-gap: 48px;

  @include breakpoint(nav) {
    padding: 24px 32px;
  }
}

.header__logo {
  display: block;
  width: 164px;
  height: 22px;
}

.main-nav {
  transition: max-height 0.2s ease-in;

  @include breakpoint(nav, down) {
    max-height: 0;
    overflow: hidden;
    text-align: center;
    flex: 0 0 100%;

  }

  @include breakpoint(nav) {
    display: block;
    flex-grow: 1;
  }
}

.main-nav__list {
  list-style-image: none;
  margin: 0;
  padding: 56px 0 24px 0;
  opacity: 0;
  transition: opacity 0.2s ease-in;
  transition-delay: 0.2s;

  @include breakpoint(nav) {
    display: flex;
    align-items: center;
    column-gap: 48px;
    padding: 0;
    opacity: 1;
  }
}

.main-nav__item {
  padding: 0;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
    margin-left: auto;
  }

  @include breakpoint(nav) {
    margin-bottom: 0;
  }

  .button {
    width: 100%;

    @include breakpoint(nav) {
      width: auto;
    }
  }
}

.main-nav__link {
  text-decoration: none;
}

.main-nav__toggle {
  display: block;
  background: transparent;
  border: none;
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;

  span {
    display: block;
    width: 30px;
    height: 2px;
    border-radius: 4px;
    background-color: var(--white);
    position: absolute;
    left: 5px;
    top: 19px;
    opacity: 1;
    transform-origin: center;
    transition: transform 0.2s ease-in, top 0.2s ease-in;

    &:first-child {
      top: 9px;
    }

    &:last-child {
      top: 29px;
    }
  }

  @include breakpoint(nav) {
    display: none;
  }
}

.header__inner--active-menu {
  .main-nav {
    max-height: 500px;
    height: auto;
  }

  .main-nav__list {
    opacity: 1;
  }

  .main-nav__toggle {
    span {
      &:first-child {
        transform: rotate(45deg);
        top: 18px;
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:last-child {
        transform: rotate(-45deg);
        top: 18px;
      }
    }
  }
}
