.approach {
  padding-top: 28px;
  background: url(../images/ellipse-bg.svg) no-repeat;
  background-position: -200px 200px;
  background-size: 1603px 804px;

  @include breakpoint(md) {
    background-position: calc(50vw - 564px) 200px;
  }
}

.approach__heading-col {
  @include breakpoint(md) {
    position: sticky;
    top: 40px;
  }
}

.approach__heading {
  margin-bottom: 64px;
}

.approach__list {
  list-style: none;
  padding: 0 0 0 40px;
  margin: 0;
  position: relative;

  @include breakpoint(md) {
    padding: 0 0 0 133px;
  }
}

.approach__line {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 11px;
  width: 2px;
  height: 100%;
  padding: 0;
  background-color: transparent;

  @include breakpoint(md) {
    left: 104px;
  }
}

.approach__line-inner {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--dark-20);
  max-height: 100%;
}

.approach__block {
  border: 1px solid var(--dark-50);
  border-radius: 8px;
  background-color: var(--card-bg);
  padding: 32px;
  position: relative;
  margin-bottom: 40px;
  backdrop-filter: blur(4px);
}

.approach__icon {
  display: block;
  width: 24px;
  height: 26px;
  position: absolute;
  top: 50%;
  left: -41px;
  z-index: 2;
}

.approach__icon-path--outer,
.approach__icon-path--inner {
  fill: var(--primary-a-100);
}

.approach__icon-path--bg {
  fill: var(--dark-100);
}

.approach__step {
  font-size: 16px;
  line-height: 28px;
  display: inline-block;
  margin-bottom: 16px;
}

.approach__title {
  margin-bottom: 16px;
}

.approach__description {
  color: var(--dark-20);
  margin-bottom: 0;
}

.no-animation {
  .approach__line-inner {
    background-color: var(--dark-20);
    height: 100%;
  }
}
