.services--home {
  padding-top: 200px;
  margin-bottom: 120px;
  background: url(../images/ellipse-bg.svg) no-repeat;
  background-size: 1603px 804px;
  background-position: -700px 0px;

  @include breakpoint(lg) {
    background-position: calc(50vw - 1300px) 0px;
  }
}

.services__heading {
  max-width: 856px;
  margin-top: 16px;
  margin-bottom: 64px;
}

.services__blocks {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  row-gap: 24px;
  column-gap: 24px;
}

.service {
  display: flex;
  flex-direction: column;
  flex: 1;
  grid-column: span 12;
  border: 1px solid var(--dark-50);
  border-radius: 8px;
  background-color: var(--dark-90);
  padding: 32px;
  position: relative;
  text-decoration: none;
  transition: background-color 0.2s ease-in, border-color 0.2s ease-in, scale 0.2s ease-in;

  @include breakpoint(md) {
    grid-column: span 6;
  }

  @include breakpoint(lg) {
    padding-right: 136px;
  }

  &:hover,
  &:focus {
    background-color: var(--dark-90);
    border-color: var(--white);
    scale: 1.02;

    .service__title {
      color: var(--white);
    }

    .button {
      background-color: var(--primary-a-100);
      color: var(--dark-100);
    }

    .service__icon {
      background-color: var(--primary-a-100);
    }
  }

  &:active {
    .button {
      background-color: var(--primary-a-80);
      color: var(--dark-100);
    }
  }
}

.body--home {
  .service {
    background-color: var(--card-bg);
    backdrop-filter: blur(4px);
  }
}

.service__title {
  margin-bottom: 16px;
}

.service__description {
  color: var(--dark-20);
  margin-bottom: 40px;
}

.service__link {
  margin-top: auto;
}

.service__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  width: 64px;
  height: 64px;
  border-radius: 16px;
  color: var(--white);
  background-color: var(--secondary-a-100);
  transition: background-color 0.2s ease-in;

  .svg-icon {
    width: 33px;
    height: 32px;
  }

  @include breakpoint(lg) {
    position: absolute;
    top: 32px;
    right: 32px;
    margin-bottom: 0;
  }
}
