.contact-items {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  row-gap: 24px;
  column-gap: 24px;
}

.contact-item {
  display: flex;
  flex-direction: column;
  flex: 1;
  grid-column: span 12;
  border: 1px solid var(--dark-50);
  border-radius: 8px;
  background-color: var(--card-bg);
  margin-top: 70px;
  padding: 40px 24px;
  position: relative;
  backdrop-filter: blur(4px);
  text-decoration: none;
  transition: background-color 0.2s ease-in, border-color 0.2s ease-in;

  .link--external {
    margin-bottom: 24px;
  }

  @include breakpoint(md) {
    grid-column: span 6;
  }

  @include breakpoint(lg) {
    grid-column: span 3;
  }
}

.contact-item__logo {
  position: absolute;
  top: -70px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  background-color: var(--white);

  .svg-logo {
    width: 64px;
    height: 64px;
  }
}

.contact-item__title {
  margin-bottom: 16px;
}

.contact-item__link {
  margin-bottom: 16px;
}

.contact-item__description {
  color: var(--dark-20);
  margin-bottom: 40px;
}

.contact-item__contact-link {
  margin-top: auto;
}

.contact-item__button {
  width: 100%;
}

.contact__copy {
  position: relative;
  display: inline-flex;

  &:hover {
    .tooltip {
      visibility: visible;
    }
  }
}

.button-copy-js {
  display: inline-flex;
  align-items: center;

  &:hover,
  &:focus {
    color: var(--white);
  }
}

.button-copy-js > svg {
  margin-left: 12px;
}

.contact__copy > .tooltip {
  visibility: hidden;
  width:  max-content;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  position: absolute;
  top: -46px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--dark-50);
  color: var(--white);
  padding: 8px;
  border-radius: 8px;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    top: 20px;
    left: 50%;
    margin-left: -10px;
    width: 20px;
    height: 20px;
    background: var(--dark-50);
    transform: rotate(315deg);
    border-radius: 0 0 0 3px;
    border-color: var(--dark-50) transparent transparent transparent;
    z-index: -2;
  }
}

.contact__copy > .tooltip--visible {
  visibility: visible;
}
