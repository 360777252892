.content__column-left {
  margin-bottom: 24px;

  @include breakpoint(lg) {
    padding-right: 40px;
  }

  .column-left__inner {
    background-color: var(--dark-90);
    padding: 40px;
    border-radius: 8px;

    & > h2:first-of-type {
      margin-top: 0;
      padding-top: 0;
    }

    p {
      a {
        color: var(--primary-a-100);
      }
    }

    hr {
      border: 1px solid var(--dark-50);
      margin: 32px 0;
    }

    @include content_typography();
  }

  .column-left__inner--no-bg {
    background-color: transparent;
    padding: 0;
  }
}

.content__column-right {
  margin-bottom: 24px;

  &.sticky {
    position: relative;

    @include breakpoint(md) {
      position: sticky;
      top: 40px;
    }
  }

  .column-right__heading {
    padding-left: 36px;
    margin-bottom: 24px;
    color: var(--dark-20);
  }

  .column-right__list-item {
    margin-bottom: 16px;
  }
}
