.certifications__list {
  list-style: none;
  padding: 40px 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 20px;
  column-gap: 4px;
  border-top: 1px solid var(--dark-50);
  border-bottom: 1px solid var(--dark-50);
}

.certifications__item {
  margin: 0 3px;
  padding: 0;
}

.certifications__image {
  max-width: 80px;
}
