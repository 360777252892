.error-page {
  position: relative;
  padding-top: 308px;
  padding-bottom: 133px;
  text-align: center;
  min-height: 100vh;
  background-color: var(--dark-90);
  z-index: -5;
}

.error-page__heading {
  margin-bottom: 16px;
}

.error-page__subheading {
  margin-bottom: 48px;
}

.main--error {
  & + footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }
}
