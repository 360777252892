.highlight .c {
  color: #998;
  font-style: italic;
}

.highlight .err {
  color: #a61717;
  background-color: #e3d2d2;
}

.highlight .k {
  font-weight: 700;
}

.highlight .o {
  font-weight: 700;
}

.highlight .cm {
  color: #998;
  font-style: italic;
}

.highlight .cp {
  color: #999;
  font-weight: 700;
}

.highlight .c1 {
  color: #998;
  font-style: italic;
}

.highlight .cs {
  color: #999;
  font-weight: 700;
  font-style: italic;
}

.highlight .gd {
  color: #000;
  background-color: #fdd;
}

.highlight .gd .x {
  color: #000;
  background-color: #faa;
}

.highlight .ge {
  font-style: italic;
}

.highlight .gr {
  color: #a00;
}

.highlight .gh {
  color: #999;
}

.highlight .gi {
  color: #000;
  background-color: #dfd;
}

.highlight .gi .x {
  color: #000;
  background-color: #afa;
}

.highlight .go {
  color: #888;
}

.highlight .gp {
  color: #555;
}

.highlight .gs {
  font-weight: 700;
}

.highlight .gu {
  color: #aaa;
}

.highlight .gt {
  color: #a00;
}

.highlight .kc {
  font-weight: 700;
}

.highlight .kd {
  font-weight: 700;
}

.highlight .kp {
  font-weight: 700;
}

.highlight .kr {
  font-weight: 700;
}

.highlight .kt {
  color: #7292F1;
  font-weight: 700;
}

.highlight .m {
  color: #099;
}

.highlight .s {
  color: #E76F8D;
}

.highlight .na {
  color: #008080;
}

.highlight .nb {
  color: #0086B3;
}

.highlight .nc {
  color: #458;
  font-weight: 700;
}

.highlight .no {
  color: #36D232;
}

.highlight .ni {
  color: #800080;
}

.highlight .ne {
  color: #900;
  font-weight: 700;
}

.highlight .nf {
  color: #900;
  font-weight: 700;
}

.highlight .nn {
  color: #555;
}

.highlight .nt {
  color: #000080;
}

.highlight .nv {
  color: #008080;
}

.highlight .ow {
  font-weight: 700;
}

.highlight .w {
  color: #bbb;
}

.highlight .mf {
  color: #099;
}

.highlight .mh {
  color: #099;
}

.highlight .mi {
  color: #099;
}

.highlight .mo {
  color: #099;
}

.highlight .sb {
  color: #d14;
}

.highlight .sc {
  color: #d14;
}

.highlight .sd {
  color: #d14;
}

.highlight .s2 {
  color: #d14;
}

.highlight .se {
  color: #d14;
}

.highlight .sh {
  color: #d14;
}

.highlight .si {
  color: #d14;
}

.highlight .sx {
  color: #d14;
}

.highlight .sr {
  color: #009926;
}

.highlight .s1 {
  color: #d14;
}

.highlight .ss {
  color: #990073;
}

.highlight .bp {
  color: #999;
}

.highlight .vc {
  color: #008080;
}

.highlight .vg {
  color: #008080;
}

.highlight .vi {
  color: #008080;
}

.highlight .il {
  color: #099;
}
