@mixin breakpoint($size, $direction: 'up') {
  $breakpoint-width: map-get($grid-breakpoints, $size);
  $media-query: 'min-width';

  @if $direction == 'down' {
    $media-query: 'max-width';
    $breakpoint-width: $breakpoint-width - 1;
  }

  @media ($media-query: $breakpoint-width) {
    @content;
  }
}

@mixin content_typography() {
  h1,
  h2,
  h3,
  h4 {
    font-family: var(--heading-font-semi-bold);
    letter-spacing: -1px;
    font-weight: 600;
  }

  h1,
  h2,
  h3 {
    color: var(--secondary-a-80);
    padding-top: 34px;
    margin-bottom: 24px;
  }

  h1 {
    font-size: 26px;
    line-height: 34px;

    @include breakpoint(lg) {
      font-size: 38px;
      line-height: 56px;
    }
  }

  h2 {
    font-size: 21px;
    line-height: 34px;

    @include breakpoint(lg) {
      font-size: 26px;
      line-height: 34px;
    }
  }

  h3 {
    font-size: 21px;
    line-height: 34px;
  }

  h4 {
    font-size: 21px;
    line-height: 34px;
    margin-bottom: 24px;
  }
}
