.footer-page {
  background-color: var(--dark-90);
  padding: 40px 20px;
  border-radius: 8px;

  @include breakpoint(md) {
    padding: 40px;
  }

  @include breakpoint(lg) {
    margin-right: 40px;
  }

  h2, h3, h4, h5, h6 {
    color: var(--secondary-a-80);
    margin-top: 32px;
    margin-bottom: 24px;
  }

  #on-this-page {
    color: var(--white);
    margin-top: 0;
  }

  h2 {
    @extend .heading-s;
  }

  h3, h4, h5, h6 {
    @extend .heading-xs;
  }

  ul, ol {
    margin-bottom: 32px;

    ul, ol {
      margin-bottom: 0px;
    }
  }

  hr {
    border: 1px solid var(--dark-50);
    margin: 32px 0;
  }

  p,
  li {
    line-height: 28px;
  }
}
