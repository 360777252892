.hero-shapes {
  position: absolute;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.hero__shape {
  position: absolute;
}

.svg-gradient {
  opacity: 0.20; // changes the opacity of the gradient shapes in the hero
}

.hero__shape-01 {
  top: 0;
  left: calc(50vw - 885px);
  z-index: -1;
  width: 1085px;
  height: 520px;
}

.hero__shape-02 {
  top: 646px;
  left: calc(50vw - 533px);
  z-index: -2;
  width: 233px;
  height: 233px;
}

.hero__shape-03 {
  top: 278px;
  left: calc(50vw + 426px);
  z-index: -3;
  width: 426px;
  height: 426px;
}

.hero__shape-04 {
  top: 519px;
  left: calc(50vw + 390px);
  z-index: -4;
  width: 290px;
  height: 290px;
}

// ANIMATION STYLES
.hero,
.error-page {
  .hero__shape {
    transition: transform 0.5s ease-in, opacity 0.5s ease-in;
    opacity: 0.8;
  }

  .hero__shape-01 {
    transform: translate(5%, 5%) scale(0.9);
  }

  .hero__shape-02 {
    transform: translate(5%, -5%) scale(0.9);
  }

  .hero__shape-03 {
    transform: translate(-5%, 5%) scale(0.9);
  }

  .hero__shape-04 {
    transform: translate(-5%, -5%) scale(0.9);
  }
}

.hero.animating,
.error-page.animating {
  .hero__shape {
    opacity: 1;
    transform: translate(0%, 0%) scale(1);
  }
}
