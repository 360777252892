.footer {
  padding: 50px 0;
  margin-top: 130px;
}

.footer__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;
}

.footer-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  row-gap: 40px;
  column-gap: 40px;
  flex-direction: column;

  @include breakpoint(xl) {
    flex-direction: row;
  }
}

.footer-menu__item {
  padding: 0;
  margin: 0;
  text-align: center;
  font-family: var(--body-font-bold);
}

.footer-menu__link {
  font-family: var(--body-font);
}
