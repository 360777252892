.button {
  display: inline-block;
  padding: 15px 24px;
  background-color: transparent;
  border: 2px solid var(--primary-a-100);
  color: var(--white);
  border-radius: 8px;
  font-family: var(--body-font-bold);
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.2s ease-in, color 0.2s ease-in;


  &:hover,
  &:focus {
    background-color: var(--primary-a-100);
    color: var(--dark-100);
  }

  &:active {
    background-color: var(--primary-a-80);
    color: var(--dark-100);
  }
}

.button--xl {
  font-size: 26px;
  line-height: 34px;
  padding: 16px 56px;
}

.button--ghost {
  display: inline-block;
  color: var(--white);
  text-decoration: underline;
  font-size: 19px;
  line-height: 32px;
  position: relative;
  padding-left: 40px;

  svg {
    width: 32px;
    height: 32px;
    fill: var(--secondary-a-100);
    position: absolute;
    top: 0;
    left: 0;
    transition: left 0.2s ease-in;
  }

  &:hover,
  &:focus,
  &:active {
    color: var(--primary-a-100);

    svg {
      left: 8px;
      fill: var(--primary-a-100);
    }
  }

  &:active {
    color: var(--primary-a-80);

    svg {
      fill: var(--primary-a-80);
    }
  }

  &.button--right {
    padding-left: 0;

    svg {
      left: 100%;
      fill: var(--white);
    }

    &:hover,
    &:focus,
    &:active {
      svg {
        left: calc(100% + 8px);
        fill: var(--primary-a-100);
      }
    }

    &:active {
      color: var(--primary-a-80);
    }
  }
}

.button--ghost-small {
  font-size: 16px;
  line-height: 28px;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: var(--primary-a-100);

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    fill: var(--white);
    position: relative;
    top: -1px;
    transition: fill 0.2s ease-in;
  }

  span {
    text-decoration: underline;
  }

  &:hover,
  &:focus,
  &:active {
    color: var(--primary-a-100);

    svg {
      fill: var(--primary-a-100);
    }
  }

  &:active {
    color: var(--primary-a-80);

    svg {
      fill: var(--primary-a-80);
    }
  }
}
