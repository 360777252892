$grid-columns: 12;
$grid-gutter: 20px;
$grid-container-max-width: 1296px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  nav: 1050px,
  xl: 1200px
);

$grid-gutters: (
  xs: 8px,
  sm: 16px,
  md: 24px,
);

$grid-container-gutters: (
  xs: 20px,
  sm: 28px,
  md: 30px,
  lg: 68px,
  xl: 72px
);
