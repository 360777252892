div.highlight {
  overflow-x: auto;
  background-color: var(--dark-100);
  border: 1px solid var(--dark-50);
  padding: 24px;

  pre {
    margin: 0;
  }
}
